<template>
  <div class="has-text-centered">
    <p class="mt-big">
      <img src="@/assets/logo.png" style="height:72px;margin-top:12px;" />
    </p>
    <div class="has-text-centered mt-big">
      <p class="text-heading bold">404: Page Not Found</p>
      <p class="text-subtitle">
        The page you are trying to access is not available, please check if the
        URL is correct.
      </p>
      <div class="mt-4">
        <router-link to="/">
          <b-button> Go to Home</b-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data() {
    return {};
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.icon-big {
  font-size: 64px;
}
.mt-big {
  margin-top: 10vh;
}
</style>
